<template>
  <!-- <div id="stat"> -->

  
    <iframe width="75%" height="100%" src="https://datastudio.google.com/embed/reporting/975cd263-c970-4253-a53c-f93254fe1788/page/CUP4C" frameborder="0" style="border:0" allowfullscreen></iframe>

<!-- </div> -->

</template>


<script>
  export default {
    data () {
    return {
      
    }
  },
  mounted () {
    // 这里就拿到了iframe的对象
    console.log(this.$refs.iframe)
    // 这里就拿到了iframe的window对象
    console.log(this.$refs.iframe.contentWindow)
  }
}

    

</script>


<style lang="scss" scoped>
  #stat{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    width: 80%;
    height: auto;
  }
</style>